<template>
  <div>
    <text-editor
      v-if="note"
      v-model="note.note_content"
      @input="updateNote"
    >
      <template #extra>
        <div class="flex gap-2">
          <b-button
            v-if="!note.is_owner"
            variant="primary"
            size="sm"
            @click="unshareSelf"
            class="w-20"
          >
            Unshare
          </b-button>

          <b-button
            v-if="note.is_owner"
            variant="primary"
            size="sm"
            @click="shareNote"
            class="w-20"
          >
            Share ({{shareCount}})
          </b-button>

          <b-button
            v-if="note.is_owner"
            variant="white"
            size="sm"
            @click="renameNote"
            class="w-20"
          >
            Rename
          </b-button>

          <b-button
            v-if="note.is_owner"
            variant="danger"
            size="sm"
            @click="deleteNote"
            class="w-20"
          >
            Delete
          </b-button>
        </div>
      </template>
    </text-editor>

    <share-modal
      v-if="shareModal.showModal"
      :data="shareModal"
      @updated="fetchShares"
    />
  </div>
</template>

<script>
const TextEditor = () => import('@/components/global/TextEditor.vue');
const ShareModal = () => import('@/components/notes/ShareModal.vue');

export default {
  name: 'Note',
  components: {
    TextEditor,
    ShareModal,
  },
  props: {
    id: Number,
  },
  computed: {
    shareCount() {
      if (this.shares === null) {
        return 0;
      }
      const shareCount = this.shares.filter((share) => share.has_access).length;
      return shareCount;
    },
  },
  data() {
    return {
      loadingCount: 0,
      savingCount: 0,
      note: null,
      shares: null,
      shareModal: {
        showModal: false,
        note_id: null,
      },
    };
  },
  methods: {
    reportSavingCount() {
      this.$emit('savingCount', this.savingCount);
    },
    focusEditor() {
      setTimeout(() => {
        const editor = document.querySelector('.ProseMirror');
        if (editor) {
          editor.focus();
          window.getSelection().selectAllChildren(editor);
          window.getSelection().collapseToEnd();
        }
      }, 100);
    },
    fetchShares() {
      if (this.id === null) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/notes/${this.id}/shares`)
        .then((res) => {
          this.shares = res.body;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch shares: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchNote() {
      if (this.id === null) {
        return;
      }
      this.loadingCount++;
      this.$http
        .get(`/notes/${this.id}`)
        .then((res) => {
          this.note = res.body;
          this.focusEditor();
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch note: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    fetchAll() {
      this.fetchNote();
      this.fetchShares();
    },
    updateNote(html) {
      const note = {
        note_content: html,
      };
      this.savingCount++;
      this.reportSavingCount();
      this.$http
        .put(`/notes/${this.note.id}`)
        .send({ note })
        .then(() => {
          // no-op
        })
        .catch((err) => {
          this.$toast.error(`Failed to update note: ${err.response.text}`);
        })
        .finally(() => {
          this.savingCount--;
          this.reportSavingCount();
        });
    },
    renameNote() {
      const noteTitle = prompt('Rename note', this.note.note_title);
      if (noteTitle === null || noteTitle.trim().length === 0) {
        return;
      }
      const note = {
        note_title: noteTitle.trim(),
      };
      this.savingCount++;
      this.reportSavingCount();
      this.$http
        .put(`/notes/${this.note.id}`)
        .send({ note })
        .then(() => {
          this.note.note_title = noteTitle.trim();
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to rename note: ${err.response.text}`);
        })
        .finally(() => {
          this.savingCount--;
          this.reportSavingCount();
        });
    },
    deleteNote() {
      if (!confirm(`Do you really wish to delete '${this.note.note_title}' note?`)) {
        return;
      }
      this.savingCount++;
      this.reportSavingCount();
      this.$http
        .delete(`/notes/${this.note.id}`)
        .then(() => {
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to delete note: ${err.response.text}`);
        })
        .finally(() => {
          this.savingCount--;
          this.reportSavingCount();
        });
    },
    shareNote() {
      this.shareModal.showModal = true;
      this.shareModal.note_id = this.note.id;
    },
    unshareSelf() {
      if (!confirm('Do you really wish to unshare yourself?')) {
        return;
      }
      this.savingCount++;
      this.reportSavingCount();
      this.$http
        .delete(`/notes/${this.note.id}/shares_self`)
        .then(() => {
          this.$emit('updated');
        })
        .catch((err) => {
          this.$toast.error(`Failed to unshare: ${err.response.text}`);
        })
        .finally(() => {
          this.savingCount--;
          this.reportSavingCount();
        });
    },
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        this.note = null;
        this.shares = null;
        this.$nextTick().then(() => {
          this.fetchAll();
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
